import { toString } from "../utils/amountUtils";

export type StatusType = "NORMAL" | "DISABLE";

export const StatusTypeChoices = [
  {
    id: "NORMAL",
    name: "正常",
  },
  { id: "DISABLE", name: "禁用" },
  { id: "DELETED", name: "删除" },
];

export type AccountType = "admin" | "user";

export const AccountTypeChoices = [
  {
    id: "admin",
    name: "管理员",
  },
  { id: "user", name: "会员" },
];

export const GenderTypeChoices = [
  {
    id: "1",
    name: "男",
  },
  { id: "2", name: "女" },
];

export const BooleanTypeChoices = [
  {
    id: "true",
    name: "是",
  },
  { id: "false", name: "否" },
];

export const PayRecordTypeData = [
  { id: "PAY", name: "充值" },
  { id: "HAND_PAY", name: "手动加款" },
  { id: "API_PAY", name: "API加款" },
  { id: "API_DEDUCT", name: "API扣除" },
  { id: "HAND_DEDUCT", name: "手动扣除" },
];

export const Locales = [
  "de",
  "en_US",
  "es",
  "fr",
  "it",
  "nl",
  "pt",
  "el",
  "sv",
  "da",
  "id",
] as const;

export type LocalesType = typeof Locales[number];

const localeMap: Record<LocalesType, string> = {
  // 英文
  en_US: "English",
  // 丹麦语
  da: "Dansk",
  // 荷蘭文
  nl: "Nederlands",
  // 法文
  fr: "Français",
  // 德文
  de: "Deutsch",
  // 意大利文
  it: "Italiano",
  // 葡萄牙文
  pt: "Português",
  // 西班牙文
  es: "Español",
  // 希臘文
  el: "Ελληνικά",
  // 瑞典文
  sv: "Svenska",
  // 印尼语
  id: "Bahasa Indonesia",
  // 繁體中文
  // zh_TW: "繁體中文",
};

export const toLocaleName = (locale?: LocalesType) => {
  if (!locale) {
    return locale;
  }
  return localeMap[locale] || locale;
};

export interface LocalesValue {
  locale: string;
  value: string;
}

export interface LocalesValue {
  locale: string;
  value: string;
}

export interface SwiperValue {
  url: string;
  link: string | undefined;
}
