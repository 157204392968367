import { MyList } from "../../components/MyList";
import { MyField } from "../../components/types";
import { Field } from "../../utils/Field";
import React from "react";
import ListAltIcon from "@mui/icons-material/ListAlt";
import { ExchangeOrderDirectionChoices } from "../../model/exchange";
import { OwOrderStatusChoices } from "../../model/ow";

const fields: MyField[] = [
  Field.objectId(),
  Field.userField(),
  Field.text({
    label: "交易对",
    source: "symbol",
  }),
  Field.select({
    label: "方向",
    source: "direction",
    choices: ExchangeOrderDirectionChoices,
  }),
  Field.select({
    label: "状态",
    source: "status",
    choices: OwOrderStatusChoices,
  }),
  Field.text({
    label: "分钟",
    source: "minute",
  }),
  Field.text({
    label: "汇率( 10 == 10% )",
    source: "rate",
    isFilter: false,
  }),
  Field.amount({
    label: "价格",
    source: "price",
  }),
  Field.amount({
    label: "最终价格",
    source: "completePrice",
  }),
  Field.amount({
    label: "数量",
    source: "amount",
  }),
  Field.dateTime({
    label: "结算时间",
    source: "endDate",
  }),

  Field.dateTime({
    label: "完成时间",
    source: "completeDate",
  }),
  Field.dateTime({
    label: "创建时间",
    source: "createdDate",
  }),

  Field.dateTime({
    label: "更新时间",
    source: "updateDate",
  }),
];

const resource = {
  list: () => {
    return (
      <MyList
        pagination={false}
        fields={fields}
        rowActions={[]}
        tableActions={<></>}
      />
    );
  },
  create: () => {
    return <></>;
  },
  edit: () => {
    return <></>;
  },
  icon: ListAltIcon,
};

export default resource;
