import { Field } from "../Field";

interface UserFieldProps {
  source?: string;
}

export function userField(props?: UserFieldProps) {
  return Field.ref({
    label: "用户",
    source: props?.source || "userId",
    reference: "users",
    referenceLabel: "uid",
    originSource: props?.source || "userId",
    minWidth: 140,
    translateLabel: (v: any) => {
      return (v.isMyUser ? "*" : "") + `${v.name}(${v.uid})`;
    },
  });
}
