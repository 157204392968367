import { fetchJson } from "../utils/fetch";
import { API_URL } from "../config";
import { Admin } from "../model/Admin";

export const AdminManagerApi = {
  unlock: async (id: string): Promise<Admin> => {
    const { data } = await fetchJson(`${API_URL}/admins/unlock/${id}`, {
      method: "POST",
    });
    return data;
  },
  isBindAuthKey: async (): Promise<boolean> => {
    const { data } = await fetchJson(`${API_URL}/account/isBindAuthKey`, {
      method: "GET",
    });
    return data;
  },
  startGoogleAuth: async (): Promise<string> => {
    const { data } = await fetchJson(`${API_URL}/account/startGoogleAuth`, {
      method: "POST",
    });
    return data;
  },
  bindMyGoogleAuth: async ({
    key,
    code,
  }: {
    key: string;
    code: string;
  }): Promise<string> => {
    const { data } = await fetchJson(`${API_URL}/account/bindMyGoogleAuth`, {
      method: "POST",
      body: new URLSearchParams({
        key,
        code,
      }),
    });
    return data;
  },
  unbindAuthKey: async ({ adminId }: { adminId: string }): Promise<string> => {
    const { data } = await fetchJson(
      `${API_URL}/account/unbindAuthKey/${adminId}`,
      {
        method: "POST",
      }
    );
    return data;
  },
};
