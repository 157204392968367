import { EditButton, WrapperField } from "react-admin";
import { MyList } from "../../components/MyList";
import { MyField } from "../../components/types";
import { Field } from "../../utils/Field";
import React from "react";
import MyCreate from "../../components/MyCreate";
import MyEdit from "../../components/MyEdit";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";

const fields: MyField[] = [
  Field.userField({
    source: "id",
  }),
  Field.editNumber({
    label: "用户一次下单数量",
    source: "owCreateOrderNum",
    isFilter: false,
    minWidth: 200,
  }),
  Field.dateTime({
    label: "创建时间",
    source: "createdDate",
  }),
  Field.dateTime({
    label: "更新时间",
    source: "updateDate",
  }),
];

const resource = {
  list: () => {
    return (
      <MyList
        fields={fields}
        rowActions={[<EditButton />]}
        tableActions={
          <WrapperField>
            <EditButton />
          </WrapperField>
        }
        // groupingColDef={groupingColDef}
      />
    );
  },
  create: () => {
    return <MyCreate fields={fields} />;
  },
  edit: () => {
    return <MyEdit fields={fields} />;
  },
  icon: SportsEsportsIcon,
};

export default resource;
