import React, { SyntheticEvent, useEffect, useState } from "react";
import SecurityIcon from "@mui/icons-material/Security";
import { Box, Stack, TextField } from "@mui/material";
import { QRCodeSVG } from "qrcode.react";
import { useRefresh } from "react-admin";
import { AdminManagerApi } from "../../api/AdminManagerApi";
import { LoadingButton } from "@mui/lab";
import { AccountStorage } from "../../AccountStorage";

const resource = {
  list: () => {
    return <MySecurityCard />;
  },
  icon: SecurityIcon,
};

function MySecurityCard() {
  const [loading, setLoading] = useState(true);
  const [isBind, setIsBind] = useState(true);

  useEffect(() => {
    let isMounted = true;
    async function isBindAuthKey() {
      let res = await AdminManagerApi.isBindAuthKey();
      if (isMounted) {
        setIsBind(res);
        setLoading(false);
      }
    }

    isBindAuthKey();
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <>
      {!loading && isBind && <Box>2FA 已经绑定成功</Box>}
      {!loading && !isBind && <BindAuthForm />}
    </>
  );
}

function BindAuthForm() {
  const [authKey, setAuthKey] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let isMounted = true;
    async function getAuthKey() {
      let res = await AdminManagerApi.startGoogleAuth();
      if (isMounted) {
        setAuthKey(res);
        setLoading(false);
      }
    }

    getAuthKey();
    return () => {
      isMounted = false;
    };
  }, []);

  const [code, setCode] = useState("");

  const codeChange = (e: any) => {
    setCode(e.target.value);
  };

  async function handleSubmit(e: SyntheticEvent) {
    let res = await AdminManagerApi.bindMyGoogleAuth({ key: authKey, code });
    console.log("handleSubmit res: ", res);
    alert(`绑定结果: ${res ? "成功" : "失败"}`);
    window.location.reload();
  }

  let admin = AccountStorage.get();

  return (
    <Stack
      padding={"32px 64px"}
      spacing={2}
      maxWidth={"500px"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      {!loading && (
        <QRCodeSVG
          value={`otpauth://totp/${window.location.host}:${admin?.name}?secret=${authKey}`}
        />
      )}
      <TextField
        label={"确认code"}
        type="number"
        fullWidth
        onChange={codeChange}
      />
      <LoadingButton loading={loading} onClick={handleSubmit}>
        保存
      </LoadingButton>
    </Stack>
  );
}

export default resource;
